import {Box, Button, Dialog, Slide, Stack, Typography} from "@mui/material";
import {forwardRef, useEffect, useState} from "react";
import useResponsive from "../../hooks/useResponsive";
import {useLocales} from "../../locales";
import policy from "../../policy.text";
import Scrollbar from "../Scrollbar/Scrollbar";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function PolicyDialog({open, handleClose}) {
    const {translate} = useLocales();
    const isMobile = useResponsive('down', 'sm');

    const [text, setText] = useState('');

    useEffect(() => {
        fetch(policy)
            .then((response) => response.text())
            .then((text) => {
                setText(text);
            });
    }, []);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                style: {
                    minWidth: '300px',
                    width: '100%',
                    maxWidth: '1200px',
                },
            }}
        >
            <Stack spacing={3.2} alignItems="center"
                   sx={{minWidth: '100%', px: 4, py: 2}}>
                <Typography sx={{
                    color: '#20415B',
                    fontFamily: 'Montserrat',
                    fontSize: isMobile ? '20px' : '24px',
                    fontWeight: 700,
                    letterSpacing: '1.2px'
                }}>
                    {translate("privatePolicy")}
                </Typography>
            </Stack>
            <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', height: "80vh"}}>
                <Scrollbar sx={{flexGrow: 1}}>
                    <Box sx={{p: 2}}>
                        <Typography component="div" sx={{
                            fontFamily: 'Montserrat',
                            fontSize: isMobile ? '14px' : '16px',
                            whiteSpace: 'pre-line',
                            textAlign: 'justify'
                        }}>
                            {text}
                        </Typography>
                    </Box>
                </Scrollbar>
            </Box>
            <Button
                fullWidth
                onClick={handleClose}
                sx={{
                    bgcolor: "none", color: '#097BC1', textTransform: 'none', fontSize: isMobile ? '14px' : '16px',
                    fontWeight: 300, letterSpacing: '.8px'
                }}>
                {translate("close")}
            </Button>
        </Dialog>
    );
}
