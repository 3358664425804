import React from 'react';
import { Box } from '@mui/material';

export default function OUPPage() {
    return (
        <Box sx={{ height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <embed
                src="/oup.pdf"
                type="application/pdf"
                width="100%"
                height="100%"
                style={{ flexGrow: 1, overflow: 'auto' }}
            />
        </Box>
    );
}
