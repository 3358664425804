import PropTypes from "prop-types";
import {createContext, useCallback, useEffect, useReducer} from "react";
import {initializeApp} from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {child, getDatabase, ref, set} from "firebase/database";
import {getStorage} from "firebase/storage";


// import {FIREBASE_API, FIREBASE_API_BUS_SOFT} from "../config";
import {FIREBASE_API} from "../config";
import {clearSession} from "../helper/sessions";

// ----------------------------------------------------------------------

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null
};

const ACTION_TYPES = {
    INITIAL: "INITIAL",
};

const reducer = (state, action) => {
    if (action.type === ACTION_TYPES.INITIAL) {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }

    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);
// const firebaseAppBUSSOFT = initializeApp(FIREBASE_API_BUS_SOFT, "bussoft");

export const AUTH = getAuth(firebaseApp);
// export const AUTH_BUSSOFT = getAuth(firebaseAppBUSSOFT);
export const DB = getFirestore(firebaseApp);
export const RDB = getDatabase(firebaseApp);
// export const RDB_BUSSOFT = getDatabase(firebaseAppBUSSOFT);
export const dbRef = ref(RDB);
// export const dbRefBUSSOFT = ref(RDB_BUSSOFT);
export const storageRef = getStorage(firebaseApp);
// export const storageBUSSOFTRef = getStorage(firebaseAppBUSSOFT);

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(() => {
        try {
            onAuthStateChanged(AUTH, (user) => {
                if (user) {
                    // const userRef = doc(DB, "users", user.uid);
                    // const docSnap = await getDoc(userRef);
                    // const profile = docSnap.data();
                    // await getUserInfo(user);

                    dispatch({
                        type: ACTION_TYPES.INITIAL,
                        payload: {
                            isAuthenticated: true,
                            user: {
                                ...user,
                                // ...profile,
                                // role: "admin",
                            },
                        },
                    });
                } else {
                    dispatch({
                        type: ACTION_TYPES.INITIAL,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        initialize();
        return () => initialize();
        // eslint-disable-next-line
    }, []);

    // LOGIN
    const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);
    // const login_bus_soft = (email, password) => signInWithEmailAndPassword(AUTH_BUSSOFT, email, password);

    // LOGOUT
    const logout = async () => {
        clearSession(true);
        await signOut(AUTH);
        dispatch({
            type: ACTION_TYPES.INITIAL,
            payload: {
                isAuthenticated: false,
                user: null,
            },
        });
    };

// REGISTRATION
    const register = async (email, password, additionalData) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(AUTH, email, password);
            const user = userCredential.user;

            await set(child(dbRef, `users/${user.uid}`), {...additionalData});
            dispatch({
                type: ACTION_TYPES.INITIAL,
                payload: {
                    isAuthenticated: true,
                    user: {
                        ...user,
                        // ...additionalData,
                    },
                },
            });

            return user;
        } catch (error) {
            console.error("Greška prilikom registracije:", error);
            throw error;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "firebase",
                login,
                // login_bus_soft,
                logout,
                register
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
