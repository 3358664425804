const ru = {
    bannerTitle: "Исследуйте доступные цены на автобусные билеты для вашего следующего приключения",
    bannerSubTitle: "Легко сравнивайте и бронируйте ваше следующее путешествие",
    appName: "NS Портал",
    signIn: "Войти",
    signOut: "Выйти",
    home: "Главная",
    profile: "Профиль",
    startStation: "Начальная станция",
    endStation: "Конечная станция",
    startDate: "Дата отъезда",
    endDate: "Дата возвращения",
    passengers: "Пассажиры",
    search: "Поиск",
    optional: "Необязательный",
    mostFavDestinations: "Наиболее популярные направления",
    startingFrom: "начиная от",
    rsd: "дин",
    downloadOurApp: "Скачайте наше приложение",
    singUpForNotifications: "Подписаться на все новые уведомления",
    termsOfUse: "Условия использования",
    privatePolicy: "Политика конфиденциальности",
    required: "Обязательное поле",
    invalidEmail: "Недействительный адрес электронной почты",
    passwordsMustMatch: "Пароли должны совпадать",
    passwordRequirements: "Пароль должен содержать хотя бы одну заглавную букву, одну цифру и один специальный символ",
    send: "Отправить",
    badUsernameAndPassword: "Неверный адрес электронной почты или пароль!",
    emailAlreadyInUse: "Адрес электронной почты уже используется!",
    appDescription: "С простым пользовательским интерфейсом, быстрым поиском и безопасной оплатой, наше приложение позволяет вам бронировать и покупать автобусные билеты из удобства вашего мобильного устройства всего в несколько шагов. Путешествие никогда не было проще – исследуйте направления, выбирайте даты и создавайте незабываемые впечатления с нашим интуитивно понятным приложением.",
    yourEmailAddress: "Ваш адрес электронной почты",
    passengersDetails: "Детали пассажиров",
    seatIsNotSelected: "Место не выбрано",
    selectGender: "Пол",
    male: "Мужской",
    female: "Женский",
    other: "Другое",
    selectSeat: "Выбрать место",
    contactDetails: "Контактные данные",
    countryFrom: "Страна рождения",
    contactPhone: "Контактный телефон",
    email: "Электронная почта",
    checkNotification: "Получать уведомления о предложениях",
    documents: "Документы",
    passport: "Паспорт",
    documentNumber: "Номер документа",
    ticketReview: "Обзор билета",
    refund: "Возврат",
    noRefund: "Без возврата",
    travelConditions: "Условия путешествия",
    condition1: "Билет необходимо показать водителю при посадке в автобус",
    condition2: "Билет можно найти в вашем аккаунте или по электронной почте после покупки",
    baggage: "Багаж",
    reservationTime: "Срок действия бронирования",
    tripDetail: "Детали поездки",
    invoiceDetails: "Детали счета",
    passenger: "Пассажир",
    basic: "Основа",
    vat: "НДС",
    discount: "Скидка",
    discountType: "Тип скидки",
    numberDiscountType: "Номер документа скидки",
    total: "Итого",
    name: "Имя",
    surname: "Фамилия",
    phone: "Телефон",
    bDay: "Дата рождения",
    sex: "Пол",
    street: "Улица",
    zip: "Почтовый индекс",
    city: "Город",
    state: "Штат",
    update: "Обновить",
    chooseRightTransport: "Выберите подходящий транспорт",
    foundResults: "Результаты найдены",
    cheapest: "Самый дешевый",
    fastest: "Самый быстрый",
    earliest: "Самый ранний",
    filter: "Фильтр",
    numberOfFreeSeats: "Количество свободных мест",
    numberOfStation: "Номер станции",
    ticketPrice: "ЦЕНА БИЛЕТА",
    advertisementTitle: "ТВОЙ JGSP NOVI SAD",
    adults: "Взрослые",
    youth: "Молодежь",
    seniors: "Пожилые",
    subscribe: "Подписаться",
    searchResult: "Результат поиска",
    routeDetails: "Детали маршрута",
    buyTicket: "Купить билет",
    stationsList: "Смотреть все станции",
    successfulPurchase: "Успешная покупка",
    busTicket: "Автобусный билет",
    invNumber: "Номер счета",
    line: "Линия",
    departure: "Отправление",
    return: "Возврат",
    departureTime: "Время отправления",
    returnTime: "Время возвращения",
    contact: "Контактная информация",
    note: "Примечание",
    downloadTicket: "Скачать билет",
    paidAmount: "Оплаченная сумма",
    paymentMethod: "Способ оплаты",
    carrierInfo: "Информация о перевозчике",
    carrierName: "Название перевозчика",
    contactNumber: "Контактный номер",
    goHome: "На главную",
    pay: "Оплатить",
    back: "Назад",
    userProfile: "Профиль пользователя",
    personalInfo: "Личная информация",
    boughtTickets: "Купленные билеты",
    logout: "Выйти",
    updatePhoto: "Обновить фото",
    personalData: "Личные данные",
    passwordChange: "Смена пароля",
    oldPassword: "Старый пароль",
    newPassword: "Новый пароль",
    welcomeBack: "Добро пожаловать обратно!",
    loginGoogle: "Продолжить с Google",
    loginEmail: "Продолжить с электронной почтой",
    login: "Войти",
    forgottenPassword: "Забыли пароль?",
    dontHaveAcc: "Нет аккаунта? ",
    haveAcc: "Уже есть аккаунт? ",
    registration: "Регистрация",
    password: "Пароль",
    rePassword: "Повторите пароль",
    privilegeType: "Тип льготы",
    privilegeNumber: "Номер льготы",
    or: "Или",
    createAccount: "Создать аккаунт",
    receiveNotifications: "Получать уведомления о акциях",
    createAccountDescriptionOne: "Быстрее покупать автобусные билеты для следующих поездок",
    createAccountDescriptionTwo: "Просмотр истории купленных билетов и предстоящих поездок",
    termsAccept: "Создавая аккаунт, вы соглашаетесь с Условиями и Политикой конфиденциальности и \nсоглашаетесь получать электронные письма, от которых вы можете отказаться в любой момент.",
    passwordResetTitle: "Сбросить пароль",
    backToLogin: "Вернуться к входу в систему",
    passwordResetText: "Введите ваш адрес электронной почты, использованный при регистрации на сайте, и мы отправим вам \nссылку для сброса пароля.",
    appSectionBulletOneTitle: "Легкая и быстрая покупка билета",
    appSectionBulletTwoTitle: "Персонализированные поиски",
    appSectionBulletThreeTitle: "Мобильная свобода",
    appSectionBulletOneDesc: "Мы предлагаем приложение с простым интерфейсом, быстрыми поисками и безопасной оплатой. Всего несколько шагов требуется для покупки автобусного билета прямо с вашего мобильного устройства.",
    appSectionBulletTwoDesc: "Мы предлагаем экономию времени и легкое планирование путешествий с помощью быстрых поисков, позволяя исследовать направления и выбирать подходящие даты для поездки.",
    appSectionBulletThreeDesc: "Мы обеспечиваем удобство и гибкость управления вашими путешествиями, покупая автобусные билеты прямо с мобильного устройства, где угодно и когда угодно.",
    futureTrips: "Предстоящие поездки",
    completedTrips: "Завершенные поездки",
    cancelledTrips: "Отмененные поездки",
    returnTicket: "Обратный билет",
    trips: "Поездки",
    save: "Сохранить",
    noOptions: "Нет вариантов",
    years: "лет",
    error: "Ошибка сервера",
    noResult: "Результатов нет",
    totalSelected: "Выбрано",
    endStationError: "Выберите конечную станцию",
    passengersAmountError: "Выберите хотя бы одного пассажира",
    subscribeSuccess: "Вы успешно подписались на уведомления.",
    emailError: "Введите действительный адрес электронной почты",
    invalidTicket: "Вы приобрели льготный билет на поезд. При пользовании транспортной услугой вы обязаны предоставить для проверки документ по требованию персонала поезда, на основании которого может быть установлено, что вы реализуете право на выбранную привилегию.",
    serbia: "Сербия",
    albania: "Албания",
    bih: "Босния и Герцеговина",
    bulgaria: "Болгария",
    montenegro: "Черногория",
    greece: "Греция",
    croatia: "Хорватия",
    northMacedonia: "Македония",
    romania: "Румыния",
    slovenia: "Словения",
    turkey: "Турция",
    invNumb: "Номер счета",
    startSt: "Начальная станция",
    withBaggage: "С багажом",
    findCheap: "Найдите дешевые",
    busTicketsFor: "автобусные билеты для",
    yourNextTrip: "вашей следующей поездки",
    findCheapBusTicketsForYourFavoriteDestinations: "Найдите дешевые автобусные билеты для ваших любимых направлений",
    easyCompareAndReservation: "НАЖМИТЕ - КУПИТЬ - ВПЕРЕД",
    subscribeForNewsletter: "Подпишитесь сейчас, чтобы получать привлекательные специальные предложения и информацию о лучших турах!",
    successMailReset: "Вы успешно сменили пароль!",
    emailErrorTitle: "Внимание! Адрес электронной почты обязателен",
    emailErrorDesc: "Пожалуйста, введите ваш адрес электронной почты, чтобы получить счет и электронный билет после успешной покупки.",
    invalidEmailErrorDesc: "Пожалуйста, введите правильный адрес электронной почты, чтобы получить счет и электронный билет после успешной покупки.",
    studentsDiscount: "Студенческий билет",
    seniorsDiscount: "Пенсионная карта",
    seatNumber: "Номер места",
    seatNumberNotDefined: "Место не нумеровано",
    emailHelper: "Адрес электронной почты обязателен",
    stationInfo: "Информация о станции",
    stationName: "Название",
    stationService: "Служба станции",
    profileIsUpdated: "Профиль успешно обновлен",
    profileIsUpdatedError: "Произошла ошибка",
    directionType: "Тип направления",
    oneWay: "В одну сторону",
    refundOnlyAtStation: "Билет можно вернуть на стойках автовокзала по адресу бул. Яше Томича 6, Нови-Сад, и не позднее, чем за 2 часа до отправления автобуса по расписанию.",
    agreeForReturn: "Я согласен с указанными условиями поездки",
    tripType: "Тип билета",
    returnTicketType: "Возвратный билет",
    oneWayTicketType: "В одну сторону",
    agreementErrorTitle: "Условия путешествия!",
    agreementErrorDesc: "Вы должны подтвердить, что согласны с условиями путешествия и политикой конфиденциальности",
    returnTimePolicyDaysValidation: "Возвратный билет открыт 90* дней",
    arrivalsDepartures: "Прибытия и отправления",
    depTime: "Отправление",
    depStation: "Станция отправления",
    lineName: "Линия",
    expectedTime: "Ожидаемое прибытие",
    carrier: "Перевозчик",
    arrivals: "Прибытия",
    departures: "Отправления",
    arrived: "Прибыл",
    notArrived: "Ожидается",
    peron: "Платформа",
    status: "Статус",
    searchDepStation: "Станция отправления",
    searchLineName: "Линия",
    searchCarrier: "Перевозчик",
    date: "Дата",
    today: "Сегодня",
    yesterday: "Вчера",
    tomorrow: "Завтра",
    isAgreedForReturnCondition: "Для возврата билета перед обратным путешествием необходимо его заверить на кассе АС",
    arrAndDep: "Прибытие",
    agreeForReturnLikeSo: "Я согласен с указанными условиями путешествия, а также с",
    termsOfUse2: "условиями использования",
    privatePolicy2: "политикой конфиденциальности",
    and: "и",
    invalidPerson: "Скидка для инвалидов",
    hideDetails: "Скрыть детали",
    showAllStations: "Показать все станции",
    invalidCardNumber: "Номер инвалидной карты",
    issuePlace: "Место выдачи",
    documentErrorDesc: "Вы должны подтвердить согласие на предъявление документа при посадке в транспорт",
    documentErrorTitle: "Необходимый документ!",
    authNumber: "Код авторизации",
    transactionId: "Номер транзакции",
    acceptETicket: "Принимает электронный билет",
    notAcceptETicket: "Этот билет необходимо распечатать на кассе автовокзала или на самообслуживающем автомате, расположенном в зале автовокзала",

}

export default ru;
