const cir = {
    bannerTitle: "Истражите повољне цене\n" +
        "аутобуских карта за\n" +
        "вашу наредну авантуру",
    bannerSubTitle: "Једноставно упоредити и резервишите ваше следеће путовање",
    appName: "НС портал",
    signIn: "Пријави се",
    signOut: "Одјави се",
    home: "Почетна",
    profile: "Профил",
    startStation: "Почетна станица",
    endStation: "Крајња станица",
    startDate: "Датум поласка",
    endDate: "Датум повратка",
    passengers: "Путници",
    search: "Претражи",
    optional: "Опционо",
    mostFavDestinations: "Најчешће биране дестинације",
    startingFrom: "већ од",
    rsd: "дин",
    downloadOurApp: "Преузмите нашу апликацију",
    singUpForNotifications: "Пријавите се за нова обавештења",
    termsOfUse: "Услови коришћења",
    privatePolicy: "Политика приватности",
    appDescription: "Са једноставним корисничким интерфејсом, брзим претрагама и сигурним плаћањем, наша апликација " +
        "вам омогућава да у свега неколико корака резервишете и купите аутобуске карте из удобности свог мобилног уређаја. " +
        "Путовање никада није било лакше – истражите дестинације, бирајте датуме и стварајте незаборавна искуства уз нашу " +
        "интуитивну апликацију.",
    yourEmailAddress: "Ваша е-адреса",
    passengersDetails: "Детаљи путника",
    seatIsNotSelected: "Место седења није дефинисано",
    selectGender: "Пол",
    male: "Мушки",
    female: "Женски",
    other: "Остало",
    selectSeat: "Изабери седиште",
    contactDetails: "Контакт подаци",
    countryFrom: "Држава рођења",
    contactPhone: "Контакт телефон",
    email: "Е-пошта",
    checkNotification: "Примај обавештења везана за понуде",
    documents: "Документи",
    passport: "Пасош",
    documentNumber: "Број документа",
    ticketReview: "Преглед карте",
    refund: "Рефундирање",
    noRefund: "Без рефундирања",
    travelConditions: "Услови путовања",
    condition1: "Карту је потребно показати возачу при уласку у возило",
    condition2: "Карту можете пронаћи на вашем налогу или мејлу након куповине",
    baggage: "Пртљаг",
    reservationTime: "Важење резервације",
    tripDetail: "Детаљи пута",
    invoiceDetails: "Ставке на рачуну",
    passenger: "Путник",
    basic: "Основица",
    vat: "Порез",
    discount: "Остварен попуст",
    discountType: "Врста повластице",
    numberDiscountType: "Број документа повластице",
    total: "Укупно",
    name: "Име",
    surname: "Презиме",
    phone: "Телефон",
    bDay: "Датум рођења",
    sex: "Пол",
    street: "Улица",
    zip: "Поштански број",
    city: "Град",
    state: "Држава",
    update: "Ажурирај",
    chooseRightTransport: "Изаберите одговарајући превоз",
    foundResults: "Резултата",
    cheapest: "Најјефтинија",
    fastest: "Најбржа",
    earliest: "Најранија",
    filter: "Филтер",
    numberOfFreeSeats: "Слободних места",
    numberOfStation: "Број перона",
    ticketPrice: "ЦЕНА КАРТЕ",
    advertisementTitle: "ВАШ ЈГСП НОВИ САД",
    adults: "Одрасли",
    youth: "Млади",
    seniors: "Сениори",
    subscribe: "Пријави се",
    searchResult: "Резултат претраге",
    routeDetails: "Детаљи релације",
    buyTicket: "Купи карту",
    stationsList: "Види све станице",
    successfulPurchase: "Успешна куповина",
    busTicket: "Аутобуска карта",
    invNumber: "Број рачуна",
    line: "Линија",
    departure: "Полазак",
    return: "Повратак",
    departureTime: "Време поласка",
    returnTime: "Време повратка",
    contact: "Контакт подаци",
    note: "Напомена",
    downloadTicket: "Преузми карту",
    paidAmount: "Уплаћен износ",
    paymentMethod: "Начин плаћања",
    carrierInfo: "Подаци превозника",
    carrierName: "Назив превозника",
    contactNumber: "Контакт телефон",
    goHome: "Иди на почетак",
    pay: "Плати",
    back: "Назад",
    userProfile: "Профил корисника",
    personalInfo: "Личне информације",
    boughtTickets: "Купљене карте",
    logout: "Одјави се",
    updatePhoto: "Промени слику",
    personalData: "Лични подаци",
    passwordChange: "Измена лозинке",
    oldPassword: "Стара лозинка",
    newPassword: "Нова лозинка",
    welcomeBack: "Добродошли назад!",
    loginGoogle: "Настави уз Google",
    loginEmail: "Настави помоћу мејла",
    login: "Пријави се",
    forgottenPassword: "Заборавили сте лозинку?",
    dontHaveAcc: "Немате отворен налог? ",
    haveAcc: "Већ имате налог? ",
    registration: "Региструј се",
    password: "Лозинка",
    rePassword: "Понови лозинку",
    privilegeType: "Тип повластице",
    privilegeNumber: "Број повластице",
    or: "Или",
    createAccount: "Направите налог",
    receiveNotifications: "Желим да примам обавештења о промоцијама",
    createAccountDescriptionOne: "Бржа куповина аутобуских карата за наредна путовања",
    createAccountDescriptionTwo: "Приказ историје купљених карата и предстојећих путовања",
    termsAccept: "Креирањем налога прихватате Услове и Политику приватности и \n" +
        "сагласни сте да примате е-поруке, на које можете да се \n" +
        "одјавите у било ком тренутку.",
    passwordResetTitle: "Обновите лозинку",
    backToLogin: "Повратак на пријављивање",
    passwordResetText: "Унесите вашу мејл адресу коју сте користили за \n" +
        "регистрацију на портал, и ми ћемо Вам послати \n" +
        "линк за обнову лозинке.",
    appSectionBulletOneTitle: "Лака резервација, брзо путовање",
    appSectionBulletTwoTitle: "Претраге по мери",
    appSectionBulletThreeTitle: "Мобилна слобода",
    appSectionBulletOneDesc: "Нудимо апликацију с једноставним корисничким интерфејсом, брзим претрагама и сигурним плаћањем. Потребно је само неколико корака до куповине аутобуске карте директно са мобилног уређаја.",
    appSectionBulletTwoDesc: "Омогућавамо брзим претрагама уштеду времена и лако планирање путовања уз истраживањедестинација и одабир одговарајућих датума за путовање.",
    appSectionBulletThreeDesc: "Обезбеђујемо практичност и флексибилност управљања путовањима куповином аутобуских карата директно са мобилног уређаја, било где и било када.",
    futureTrips: "Предстојећа путовања",
    completedTrips: "Реализована путовања",
    cancelledTrips: "Отказана путовања",
    returnTicket: "Повратна",
    trips: "Путовања",
    save: "Сачувај",
    noOptions: "Без резултата",
    years: "година",
    error: "Грешка на серверу",
    noResult: "Нема резултата",
    totalSelected: "Изабрано",
    endStationError: "Изаберите крајњу станицу",
    passengersAmountError: "Изаберите бар једног путника",
    subscribeSuccess: "Успешно сте се пријавили за примање обавештења.",
    emailError: "Унесите валидну мејл адресу",
    invalidTicket: "Купили сте повлашћену возну карту. Приликом коришћења услуге превоза дужни сте да на захтев возног особља пружите на увид исправу на основу које се може утврдити да остварујете право на одабрану повластицу",
    serbia: "Србија",
    albania: "Албанија",
    bih: "Босна и Херцеговина",
    bulgaria: "Бугарска",
    montenegro: "Црна Гора",
    greece: "Грчка",
    croatia: "Хрватска",
    northMacedonia: "Македонија",
    romania: "Румунија",
    slovenia: "Словенија",
    turkey: "Турска",
    required: "Обавезно поље",
    invalidEmail: "Мејл није валидан",
    passwordsMustMatch: "Лозинке морају бити исте",
    passwordRequirements: "Лозинка мора имати бар једно велико слово, један број и један специјални карактер",
    send: "Пошаљи",
    badUsernameAndPassword: "Мејл или лозинка нису валидни!",
    emailAlreadyInUse: "Мејл је већ заузет!",
    invNumb: "Број рачуна",
    startSt: "Почетна станица",
    withBaggage: "Са пртљагом",
    findCheap: "Пронађите повољне",
    busTicketsFor: "аутобуске карте за",
    yourNextTrip: "ваше следеће путовање",
    findCheapBusTicketsForYourFavoriteDestinations: "Пронађите повољне аутобуске карте за ваше омиљене дестинације",
    easyCompareAndReservation: "КЛИКНИ - КУПИ - КРЕНИ",
    subscribeForNewsletter: "Пријавите се сада како бисте примали атрактивне посебне понуде и информације о најбољим турама!",
    successMailReset: "Успешно сте променили лозинку!",
    emailErrorTitle: "Упозорење! Мејл адреса је обавезна",
    emailErrorDesc: "Молимо Вас да унесете вашу мејл адресу како бисте добили рачун и електронску карту након успешне куповине.",
    invalidEmailErrorDesc: "Молимо Вас унесите исправну мејл адресу како бисте добили рачун и електронску карту након успешне куповине.",
    studentsDiscount: "Студентска картица",
    seniorsDiscount: "Пензионерска картица",
    seatNumber: "Број места седења",
    seatNumberNotDefined: "Седиште није нумерисано",
    emailHelper: "Мејл адреса је обавезан податак",
    stationInfo: "Подаци станице",
    stationName: "Назив",
    stationService: "Станична услуга",
    profileIsUpdated: "Успешно сте ажурирали профил",
    profileIsUpdatedError: "Дошло је до грешке",
    directionType: "Смер",
    oneWay: "Један смер",
    refundOnlyAtStation: "Карту је могуће вратити на шалтерима аутобуске станице на адреси Бул. Јаше Томића 6, Нови Сад, а најкасније 2х пре назначеног поласка аутобуса",
    agreeForReturn: "Слажем се са наведеним условима путовања",
    tripType: "Тип карте",
    returnTicketType: "Повратна карта",
    oneWayTicketType: "Један смер",
    agreementErrorTitle: "Услови путовања!",
    agreementErrorDesc: "Морате потврдити да сте сагласни са условима путовања и политиком приватности",
    returnTimePolicyDaysValidation: "Повратна карта је отворена 90* дана",
    arrivalsDepartures: "Доласци и одласци",
    depTime: "Полазак",
    depStation: "Полазна станица",
    lineName: "Линија",
    expectedTime: "Очекиван долазак",
    carrier: "Превозник",
    arrivals: "Доласци",
    departures: "Поласци",
    arrived: "Стигао",
    notArrived: "Чека се",
    peron: "Перон",
    status: "Статус",
    searchDepStation: "Полазна станица",
    searchLineName: "Линија",
    searchCarrier: "Превозник",
    date: "Датум",
    today: "Данас",
    yesterday: "Јуче",
    tomorrow: "Сутра",
    isAgreedForReturnCondition: "Повратну карту је пре повратног путовања, потребно оверити на шалтеру АС",
    arrAndDep: "Доласци",
    agreeForReturnLikeSo: "Слажем се са наведеним условима путовања као и са",
    termsOfUse2: "условима коришћења",
    privatePolicy2: "политиком приватности",
    and: "и",
    invalidPerson: "Попуст за инвалиде",
    hideDetails: "Сакриј детаље",
    showAllStations: "Прикажи све станице",
    invalidCardNumber: "Број инвалидске књижице",
    issuePlace: "Место издавања",
    documentErrorDesc: "Морате потврдити да сте сагласни са давањем документа на увид при уласку у возило",
    documentErrorTitle: "Обавезан документ!",
    authNumber: "Ауторизациони код",
    transactionId: "Број трансакције",
    privacyTitle: "Политика приватности",
    termsTitle: "Услови коришћења",
    acceptETicket: "Прихвата е-карту",
    notAcceptETicket: "Ову карту морате штампати на шалтеру аутобуске станице или на самоуслужном апарату који се налази у шалтер сали аутобуске станице",
}

export default cir;
