import axios from "axios";

export const customSpringAxios = axios.create({
    baseURL: "https://api-lw64c4rxja-lm.a.run.app/api/bussoft",
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

customSpringAxios.interceptors.request.use(
    async (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);


customSpringAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // if (error.response.status === 401 && !originalRequest._retry) {
        // }
        return Promise.reject((error.response && error.response.data) || "Something went wrong");
    },
);
