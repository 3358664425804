import React, { createContext, useContext, useState } from "react";

const LoadingContext = createContext(null);

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);

    const startLoading = () => {
        if (!loading) {
            setLoading(true);
        }
    };
    const startLoadingSkeleton = () => {
        if (!loadingSkeleton) {
            setLoadingSkeleton(true);
        }
    };


    const endLoading = () => {
        setLoading(false);
    };
    const endLoadingSkeleton = () => {
        setLoadingSkeleton(false);
    };

    return (
        <LoadingContext.Provider
            value={{
                loading,
                startLoading,
                endLoading,
                startLoadingSkeleton,
                endLoadingSkeleton,
                loadingSkeleton
        }}>
            {children}
        </LoadingContext.Provider>
    );
};


function useLoading() {
    return useContext(LoadingContext);
}

export { LoadingContext, useLoading };
