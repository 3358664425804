import {useEffect} from "react";
import Dexie from "dexie";

import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import ThemeProvider from "./theme";
import {ThemeSettings} from "./components/settings";
import {SnackbarProvider} from "notistack";
import {LoadingProvider} from "./context/Loading";
import Router from "./routes";
import TimerProvider from "./context/TimerContext";
import {SearchResultProvider} from "./context/SearchResultContext";
import {OFFLINE_DB_NAME} from "./constants/dexie";
import {createDB} from "./store/offlineDB";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

function App() {
    useEffect(() => {
        Dexie.exists(OFFLINE_DB_NAME).then(exists => {
            if (!exists) {
                createDB();
            }
        }).catch(reason => {
            console.error(reason);
        });
    }, []);

    return (
        <MotionLazyContainer>
            <GoogleReCaptchaProvider reCaptchaKey="6Lcz4REqAAAAAHrr1sKLwN9qg_Yv8R3W5kLDyAhY"
                                     scriptProps={{
                                         async: true,
                                         defer: true,
                                         appendTo: 'body',
                                         nonce: undefined,
                                     }}>
                <ThemeProvider>
                    <ThemeSettings>
                        <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                            <LoadingProvider>
                                <TimerProvider>
                                    <SearchResultProvider>
                                        <Router/>
                                    </SearchResultProvider>
                                </TimerProvider>
                            </LoadingProvider>
                        </SnackbarProvider>
                    </ThemeSettings>
                </ThemeProvider>
            </GoogleReCaptchaProvider>
        </MotionLazyContainer>
    );
}

export default App;
