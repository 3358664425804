const en = {
    bannerTitle: "Explore affordable bus ticket prices for your next adventure",
    bannerSubTitle: "Easily compare and book your next trip",
    appName: "NS Portal",
    signIn: "Sign in",
    signOut: "Sign out",
    home: "Home",
    profile: "Profile",
    startStation: "Starting station",
    endStation: "End station",
    startDate: "Departure date",
    endDate: "Return date",
    passengers: "Passengers",
    search: "Search",
    optional: "Optional",
    mostFavDestinations: "Most frequently chosen destinations",
    startingFrom: "starting from",
    rsd: "RSD",
    downloadOurApp: "Download our app",
    singUpForNotifications: "Sign up for all new notifications",
    termsOfUse: "Terms of use",
    privatePolicy: "Privacy policy",
    appDescription: "With a simple user interface, fast searches, and secure payment, our app allows you to book and purchase bus tickets in just a few steps from the comfort of your mobile device. Traveling has never been easier – explore destinations, pick dates, and create unforgettable experiences with our intuitive app.",
    yourEmailAddress: "Your email address",
    passengersDetails: "Passenger details",
    seatIsNotSelected: "Seat is not selected",
    selectGender: "Select gender",
    male: "Male",
    female: "Female",
    other: "Other",
    selectSeat: "Select seat",
    contactDetails: "Contact details",
    countryFrom: "Country of birth",
    contactPhone: "Contact phone",
    email: "Email",
    checkNotification: "Receive offer-related notifications",
    documents: "Documents",
    passport: "Passport",
    documentNumber: "Document number",
    ticketReview: "Ticket review",
    refund: "Refund",
    noRefund: "No refund",
    travelConditions: "Travel conditions",
    condition1: "Ticket must be shown to the driver upon entering the vehicle",
    condition2: "You can find the ticket in your account or email after purchase",
    baggage: "Baggage",
    reservationTime: "Reservation validity",
    tripDetail: "Trip details",
    invoiceDetails: "Invoice details",
    passenger: "Passenger",
    basic: "Basic",
    vat: "Tax",
    discount: "Discount amount",
    discountType: "Type of discount",
    numberDiscountType: "Discount document number",
    total: "Total",
    name: "Name",
    surname: "Surname",
    phone: "Phone",
    bDay: "Date of birth",
    sex: "Gender",
    street: "Street",
    zip: "Zip code",
    city: "City",
    state: "State",
    update: "Update",
    chooseRightTransport: "Choose the right transport",
    foundResults: "Results",
    cheapest: "Cheapest",
    fastest: "Fastest",
    earliest: "Earliest",
    filter: "Filter",
    numberOfFreeSeats: "Number of free seats",
    numberOfStation: "Number of platform",
    ticketPrice: "TICKET PRICE",
    advertisementTitle: "YOUR JGSP NOVI SAD",
    adults: "Adults",
    youth: "Youth",
    seniors: "Seniors",
    subscribe: "Subscribe",
    searchResult: "Search result",
    routeDetails: "Route details",
    buyTicket: "Buy ticket",
    stationsList: "See all stations",
    successfulPurchase: "Successful purchase",
    busTicket: "Bus ticket",
    invNumber: "Invoice number",
    line: "Line",
    departure: "Departure",
    return: "Return",
    departureTime: "Departure time",
    returnTime: "Return time",
    contact: "Contact details",
    note: "Note",
    downloadTicket: "Download ticket",
    paidAmount: "Paid amount",
    paymentMethod: "Payment method",
    carrierInfo: "Carrier information",
    carrierName: "Carrier name",
    contactNumber: "Contact number",
    goHome: "Go to home",
    pay: "Pay",
    back: "Back",
    userProfile: "User profile",
    personalInfo: "Personal information",
    boughtTickets: "Bought tickets",
    logout: "Log out",
    updatePhoto: "Update photo",
    personalData: "Personal data",
    passwordChange: "Change password",
    oldPassword: "Old password",
    newPassword: "New password",
    welcomeBack: "Welcome back!",
    loginGoogle: "Continue with Google",
    loginEmail: "Continue with email",
    login: "Log in",
    forgottenPassword: "Forgot password?",
    dontHaveAcc: "Don't have an account? ",
    haveAcc: "Already have an account? ",
    registration: "Register",
    password: "Password",
    rePassword: "Repeat password",
    privilegeType: "Type of privilege",
    privilegeNumber: "Privilege number",
    or: "Or",
    createAccount: "Create account",
    receiveNotifications: "I would like to receive promotional notifications",
    createAccountDescriptionOne: "Faster purchase of bus tickets for upcoming trips",
    createAccountDescriptionTwo: "View the history of purchased tickets and upcoming trips",
    termsAccept: "By creating an account, you accept the Terms and Privacy Policy and agree to receive emails, which you can unsubscribe from at any time.",
    passwordResetTitle: "Reset password",
    backToLogin: "Back to login",
    passwordResetText: "Enter the email address you used to register on the portal, and we will send you a link to reset your password.",
    appSectionBulletOneTitle: "Easy and Quick Ticket Purchase",
    appSectionBulletTwoTitle: "Customized Searches",
    appSectionBulletThreeTitle: "Mobile Freedom",
    appSectionBulletOneDesc: "We offer an app with a simple user interface, fast searches, and secure payments. It only takes a few steps to purchase a bus ticket directly from your mobile device.",
    appSectionBulletTwoDesc: "We enable time-saving and easy travel planning with quick searches, allowing you to explore destinations and choose suitable travel dates.",
    appSectionBulletThreeDesc: "We provide convenience and flexibility in managing your travels by purchasing bus tickets directly from your mobile device, anywhere and anytime.",
    futureTrips: "Upcoming trips",
    completedTrips: "Completed trips",
    cancelledTrips: "Cancelled trips",
    returnTicket: "Return ticket",
    trips: "Trips",
    save: "Save",
    noOptions: "No options",
    years: "years",
    error: "Server error",
    noResult: "No results",
    totalSelected: "Selected",
    endStationError: "Select end station",
    passengersAmountError: "Select at least one passenger",
    subscribeSuccess: "You have successfully subscribed to notifications.",
    emailError: "Enter a valid email address",
    invalidTicket: "You have purchased a preferential train ticket. When using the transportation service, you are obliged to provide a document for inspection at the request of the train staff, on the basis of which it can be determined that you exercise the right to the selected privilege",
    serbia: "Serbia",
    required: "Required field",
    invalidEmail: "Invalid email",
    passwordsMustMatch: "Passwords must match",
    passwordRequirements: "Password must have at least one uppercase letter, one number, and one special character",
    send: "Send",
    badUsernameAndPassword: "Invalid email or password!",
    emailAlreadyInUse: "Email is already in use!",
    invNumb: "Invoice number",
    startSt: "Start station",
    withBaggage: "With baggage",
    findCheap: "Find cheap",
    busTicketsFor: "bus tickets for",
    yourNextTrip: "your next trip",
    findCheapBusTicketsForYourFavoriteDestinations: "Find cheap bus tickets to your favorite destinations",
    easyCompareAndReservation: "CLICK - BUY - GO",
    subscribeForNewsletter: "Sign up now to receive attractive special offers and information on the best tours!",
    successMailReset: "Successfully changed your password!",
    emailErrorTitle: "Warning! Email address is required",
    emailErrorDesc: "Please enter your email address to receive a receipt and e-ticket after a successful purchase.",
    invalidEmailErrorDesc: "Please enter a valid email address to receive a receipt and e-ticket after a successful purchase.",
    studentsDiscount: "Student card",
    seniorsDiscount: "Senior card",
    seatNumber: "Seat number",
    seatNumberNotDefined: "Seat is not numbered",
    emailHelper: "Email address is a required field",
    stationInfo: "Station information",
    stationName: "Name",
    stationService: "Station service",
    profileIsUpdated: "Successfully updated profile",
    profileIsUpdatedError: "An error occurred",
    directionType: "Direction",
    oneWay: "One way",
    refundOnlyAtStation: "The ticket can be returned at the counters of the bus station at Bul. Jaše Tomića 6, Novi Sad, and no later than 2 hours before the scheduled departure of the bus",
    agreeForReturn: "I agree with the mentioned travel conditions",
    tripType: "Ticket type",
    returnTicketType: "Return ticket",
    oneWayTicketType: "One way",
    agreementErrorTitle: "Travel conditions!",
    agreementErrorDesc: "You must confirm that you agree to the terms of travel and the privacy policy",
    returnTimePolicyDaysValidation: "Return ticket is open for 90* days",
    arrivalsDepartures: "Arrivals and departures",
    depTime: "Departure",
    depStation: "Departure station",
    lineName: "Line",
    expectedTime: "Expected arrival",
    carrier: "Carrier",
    arrivals: "Arrivals",
    departures: "Departures",
    arrived: "Arrived",
    notArrived: "Waiting",
    peron: "Platform",
    status: "Status",
    searchDepStation: "Departure station",
    searchLineName: "Line",
    searchCarrier: "Carrier",
    date: "Date",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    isAgreedForReturnCondition: "Before the return journey, the return ticket needs to be validated at the AS counter",
    arrAndDep: "Arrivals",
    agreeForReturnLikeSo: "I agree with the stated travel conditions as well as with",
    termsOfUse2: "terms of use",
    privatePolicy2: "privacy policy",
    and: "and",
    invalidPerson: "Discount for disabled",
    hideDetails: "Hide details",
    showAllStations: "Show all stations",
    invalidCardNumber: "Invalid card number",
    issuePlace: "Issue place",
    documentErrorDesc: "You must confirm that you agree to present the document for inspection upon entering the vehicle",
    documentErrorTitle: "Document required!",
    authNumber: "Authorization code",
    transactionId: "Transaction number",
    privacyTitle: "Privacy Policy",
    termsTitle: "Terms of Use",
    acceptETicket: "Accepts e-ticket",
    notAcceptETicket: "This ticket must be printed at the bus station counter or at the self-service machine located in the bus station hall",

}

export default en;
